body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-image: url(./bg.jpg);
  background-repeat: no-repeat;
  background-size: cover, 100%;
  background-attachment: fixed;
}
.menuEl {
  max-width: 500px;
}
.menuElMobile { 
  max-width: 200px;
}
.hide{
  display: none;
}